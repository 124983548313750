<template>
    <div>
        <div v-for="(category, index) in categories" class="c-category-tree__section" :class="{ 'c-category-tree__category': level === 0 }" :key="index">
            <form @submit.prevent="updateCategory(category)" class="c-category-tree__item">
              <mercur-input class="flex-grow-1" v-model="category.name">
                Category name
              </mercur-input>
              <mercur-input type="number" v-model.number="category.margin" step="any" class="c-category-tree__item-margin">
                Margin
              </mercur-input>
              <mercur-select v-model="category.marginType">
                <template #label>Type</template>
                <option value="STATIC">Static (€)</option>
                <option value="DYNAMIC">Dynamic (%)</option>
              </mercur-select>
              <div class="c-category-tree__item-action">
                  <mercur-button type="submit" class="btn btn-icon btn-icon-mini" v-if="hasPermission('MerchantCentral/updateCategory')" :disabled="category.categoryId === loadingId"><i class="fas fa-save"></i></mercur-button>
                  <mercur-button class="btn btn-icon btn-icon-mini c-category-tree__btn-delete" v-if="hasPermission('MerchantCentral/removeCategory')" :disabled="category.categoryId === loadingId"  @click.native.prevent="removeCategory(category)"><i class="fas fa-trash"></i></mercur-button>
                  <mercur-button :disabled="fetching" @click.native.prevent="openCategory(category)" class="btn btn-yellow text-white btn-icon btn-icon-mini c-category-tree__item-expand"><i class="fas fa-arrow-right"></i></mercur-button>
              </div>
                <mercur-button v-if="hasPermission('MerchantCentral/addCategory')" @click.native.prevent="addNewCategory(category.categoryId)" :disabled="category.categoryId === loadingId" class="btn btn-icon btn-yellow text-white btn-icon-sm c-category-tree__btn-floating"><i class="fas fa-plus"></i></mercur-button>
            </form>
            <template v-if="category.categories && category.categories.length > 0">
                <category-tree-item :categories="category.categories" :level="level + 1" :fetching="fetching" :loadingId="loadingId"></category-tree-item>
            </template>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CategoryTreeItem',
    props: {
        categories: {
            default: [],
        },
        level: {
            default: 0,
        },
        loadingId: {
            default: null,
        },
        fetching: {
            default: false,
        },
    },
    methods: {
        addNewCategory (categoryId) {
            this.$bus.$emit('addSubCategory', categoryId)
        },
        removeCategory (category) {
            this.$bus.$emit('removeCategory', category)
        },
        updateCategory (category) {
            this.$bus.$emit('updateCategory', category)
        },
        openCategory (category) {
            this.$bus.$emit('openCategory', category)
        },
    },
}
</script>
