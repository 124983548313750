<template>
    <div class="c-category-tree">
        <h3 class="font-weight-normal">Add category
            <mercur-button class="btn btn-icon btn-yellow text-white btn-icon-mini" @click.native="addNewCategory">
                <i class="fas fa-plus"></i>
            </mercur-button>
        </h3>
        <template v-if="hasPermission('MerchantCentral/listCategories')">
            <div v-if="categories && categories.length">
                <category-tree-item :categories="categories" :fetching="isFetchingCategories" :loadingId="loadingId"></category-tree-item>
            </div>

            <mercur-dialog :is-open.sync="isDialogActive">
                <h3 class="font-weight-normal">{{ activeCategory ? activeCategory.categoryName : '' }}</h3>
                <p>Are you sure you want to remove this category?</p>
                <template #footer>
                    <mercur-button class="btn" @click.native="isDialogActive = false">Cancel</mercur-button>
                    <mercur-button class="btn btn-primary" @click.native="confirmRemove">Confirm</mercur-button>
                </template>
            </mercur-dialog>
        </template>
        <template v-else>
            Not allowed to see this view
        </template>
    </div>
</template>

<script>
import CategoryTreeItem from '@/components/elements/products/CategoryTreeItem'
import { mapState, mapActions } from 'vuex'
export default {
    name: 'CategoryTree',
    components: { CategoryTreeItem },
    data () {
        return {
            activeCategory: null,
            isDialogActive: false,
            loadingId: false,
            isFetchingCategories: false,
        }
    },
    computed: {
        ...mapState('category', ['categories']),
    },
    methods: {
        ...mapActions('category', ['fetchCategories', 'addCategory', 'updateCategory', 'removeCategory', 'addChildCategory']),
        addNewCategory () {
            this.addCategory({
                name: 'Category',
                margin: 0,
                marginType: 'STATIC',
                catalogId: this.$uuid.v4(),
            })
        },
        addChild (categoryId) {
            if (!this.categories) {
                return
            }
            this.addChildCategory({
                categoryId: categoryId,
            })
        },
        triggerRemoveDialog () {
            if (!this.hasPermission('removeCategory')) {
                return
            }
            this.isDialogActive = true
        },
        confirmRemove () {
            this.loadingId = this.activeCategory.categoryId
            this.removeCategory({
                categoryId: this.activeCategory.categoryId,
            }).then(() => {
                this.isDialogActive = false
            })
        },
        listenRemove (category) {
            this.activeCategory = category
            this.triggerRemoveDialog()
        },
        listenUpdate (category) {
            this.loadingId = category.categoryId
            this.updateCategory({ category }).then(() => {
                this.loadingId = null
            })
        },
    },
    created () {
        if (!this.hasPermission('listCategories')) {
            return
        }
        this.isFetchingCategories = true
        this.fetchCategories().then(() => {
            this.isFetchingCategories = false
        })
    },
    mounted () {
        this.$bus.$on('addSubCategory', this.addChild)
        this.$bus.$on('removeCategory', this.listenRemove)
        this.$bus.$on('updateCategory', this.listenUpdate)
    },
    destroyed () {
        this.$bus.$off('addSubCategory', this.addChild)
        this.$bus.$off('removeCategory', this.listenRemove)
        this.$bus.$off('updateCategory', this.listenUpdate)
    },
}
</script>
