<template>
    <div class="c-product-view pb-5">
        <v-title title="Categories"></v-title>
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Categories</h1>
                </div>
            </div>
        </title-box>
        <div class="container-fluid mt-3">
            <template v-if="hasPermission('MerchantCentral/listCategories')">
                <div class="row">
                    <div class="col-6">
                        <mercur-card>
                            <category-tree></category-tree>
                        </mercur-card>
                    </div>
                    <div class="col-6">
                        <div v-if="currentCategory">
                            <mercur-card class="mb-4">
                                <h3 class="font-weight-normal">Products for {{ currentCategory.name }}</h3>
                                <merchant-data-table style="height: 300px;"  :items="currentCategory.products" :isDefaultSize="false" :options="selectedProductsOptions"></merchant-data-table>
                            </mercur-card>
                            <mercur-card class="mb-4">
                                <h3 class="mb-0 font-weight-normal">
                                    All Products
                                    <quick-search style="display: inline-flex; margin-left: 15px;" :quickSearch.sync="filters.search" />
                                </h3>
                                <template v-if="url && agreement">
                                    <merchant-data-table style="height: 1250px;" :options="options" :quickSearch="filters.search" :url="url" ref="table"></merchant-data-table>
                                </template>
                                <p v-else>Shop has no agreement</p>
                            </mercur-card>
                        </div>
                    </div>
                </div>
                <div>
                    <mercur-dialog :is-open.sync="isDialogOpen">
                        <div class="mb-3">
                            <span class="mr-2">
                                <input type="radio" id="useCategoryMargin" v-model="isCustomMargin" :value="false">
                                <label for="useCategoryMargin">Use category margin</label>
                            </span>
                            <span>
                                <input type="radio" id="useCustomMargin" v-model="isCustomMargin" :value="true">
                                <label for="useCustomMargin">Use custom margin for product</label>
                            </span>
                        </div>
                        <div v-if="isCustomMargin">
                            <mercur-input v-model="customMargin.margin" type="number" step="any">
                                Margin value
                            </mercur-input>
                            <mercur-select v-model="customMargin.marginType">
                                <template #label>Margin type</template>
                                <option value="STATIC">Static (€)</option>
                                <option value="DYNAMIC">Dynamic (%)</option>
                            </mercur-select>
                        </div>
                        <template #footer>
                            <mercur-button class="btn" :disabled="loading" @click.native="isDialogOpen = false">Close</mercur-button>
                            <mercur-button class="btn btn-primary" v-if="hasPermission('MerchantCentral/addProductsToCategory')" :disabled="loading" @click.native="addProduct">Save</mercur-button>
                        </template>
                    </mercur-dialog>
                </div>
            </template>
            <template v-else>
                Not allowed to see this view
            </template>
        </div>
    </div>
</template>

<script>
import CategoryTree from '@/components/elements/products/CategoryTree'
import CONFIG from '@root/config'
import CheckboxCell from '@/components/elements/table/CheckboxCell'
import QuickSearch from '@/components/elements/table/QuickSearch'
import { mapActions } from 'vuex'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
export default {
    name: 'CategoriesView',
    components: { CategoryTree, MerchantDataTable, QuickSearch },
    data () {
        return {
            currentCategory: null,
            agreement: null,
            loading: false,
            isDialogOpen: false,
            isCustomMargin: false,
            customMargin: {},
            activeProduct: null,
            selectedProductsOptions: {
                columns: {
                    'Label': { field: 'label', width: 100, maxWidth: 100 },
                    'Attributes': {
                        field: 'options',
                        cellRenderer: (params) => {
                            let text = ''
                            for (let key in params.value) {
                                text = text + `${key}: ${params.value[key]} <br>`
                            }
                            return text
                        },
                        cellClass: 'c-standard-table__cell--multi-line',
                    },
                },
            },
            filters: {
                search: '',
            },
            options: {
                columns: {
                    '': {
                        field: 'selected',
                        width: 35,
                        maxWidth: 35,
                        cellRendererFramework: CheckboxCell,
                        cellRendererParams: (params) => {
                            return {
                                disabled: this.loading,
                                getValue: () => {
                                    if (this.currentCategory.products.find((item) => item.label === params.data.label)) {
                                        return true
                                    }
                                    return false
                                },
                                setValue: (value) => {
                                    if (value === true) {
                                        if (!this.hasPermission('addProductsToCategory')) {
                                            return
                                        }
                                        this.isDialogOpen = true
                                        this.activeProduct = params
                                    } else {
                                        if (!this.hasPermission('removeProductsFromCategory')) {
                                            return
                                        }
                                        this.removeProduct(params).then(() => {
                                            this.currentCategory.products = this.currentCategory.products.filter((item) => item.label !== params.data.label)
                                        })
                                    }
                                },
                                isDisabled: () => {
                                    return this.loading
                                },
                            }
                        },
                    },
                    'Label': {
                        field: 'label',
                        filterParams: {
                            filterOptions: ['contains'],
                            newRowsAction: 'keep',
                            textFormatter: (value) => {
                                return value.trim()
                            },
                        },
                    },
                    'Options': {
                        field: 'options',
                        cellRenderer: (params) => {
                            let text = ''
                            for (let key in params.value) {
                                text = text + `${key}: ${params.value[key]} <br>`
                            }
                            return text
                        },
                        cellClass: 'c-standard-table__cell--multi-line',
                    },
                },
                quickSearchColumns: ['label'],
                paginationPageSize: 25,
                cacheBlockSize: 25,
            },
        }
    },
    computed: {
        url () {
            if (!this.agreement) {
                return null
            }
            return CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.LIST_PRODUCT_LABELS_PER_SHOP.replace('{shopHash}', this.agreement.shopHash)
        },
    },
    methods: {
        ...mapActions('category', ['addProductToCategory', 'removeProductFromCategory']),
        async addProduct () {
            const params = this.activeProduct
            this.loading = true
            const url = CONFIG.API.ROUTES.CATEGORIES.ADD_PRODUCT.replace('{categoryId}', this.currentCategory.categoryId).replace('{catalogId}', this.currentCategory.catalogId)
            let margin = {
                marginType: this.currentCategory.marginType,
                marginValue: this.currentCategory.margin,
            }

            if (this.isCustomMargin) {
                margin = {
                    marginType: this.customMargin.marginType,
                    marginValue: this.customMargin.margin,
                }
            }
            await this.addProductToCategory({
                product: params.data,
                url: url,
                marginType: margin.marginType,
                marginValue: margin.marginValue,
            }).then(() => {
                this.currentCategory.products.push(params.data)
                this.customMargin = {}
                this.activeProduct = null
            }).finally(() => {
                this.loading = false
                this.isDialogOpen = false
            })
        },
        async removeProduct (params) {
            this.loading = true
            const url = CONFIG.API.ROUTES.CATEGORIES.REMOVE_PRODUCT.replace('{categoryId}', this.currentCategory.categoryId).replace('{catalogId}', this.currentCategory.catalogId)
            await this.removeProductFromCategory({
                product: params.data,
                url: url,
            }).then(() => {
                this.loading = false
            })
        },
        listen (category) {
            this.currentCategory = category
        },
        getAgreementForApplication () {
            const url = CONFIG.API.ROUTES.PRODUCTS_MANAGEMENT.GET_AGREEMENTS
            this.$api.get(url).then(({ data }) => {
                this.$set(this, 'agreement', data.data[0])
            })
        },
    },
    mounted () {
        this.$bus.$on('openCategory', this.listen)
        this.getAgreementForApplication()
    },
    destroyed () {
        this.$bus.$off('openCategory', this.listen)
    },
}
</script>
